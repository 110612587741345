import { TopNav } from "components/shared/TopNav";
import { useTranslation } from "react-i18next";
import { useStore } from "structure";
import { useNavigate, useParams } from "react-router-dom";
import { Form, Field } from "react-final-form";
import { Loader } from "semantic-ui-react";
import * as S from "./style";
import { observer } from "mobx-react-lite";

export const Edit = observer(() => {
  const { storageStore, cardsStore, lang } = useStore();
  const { t } = useTranslation();
  let { cardId } = useParams();
  const card = storageStore.getCardById(cardId);
  const navigate = useNavigate();

  const onSubmit = async (values) => {
    storageStore.updateCard(card.id, {
      type: card.type,
      number: values.number,
      cvv: values.cvv,
      expire: values.expire,
      balance: [...card.balance, { amount: values.amount, date: new Date() }],
    });
    navigate("/");
  };

  if (card) {
    return (
      <S.Wrapper>
        <TopNav>
          <item to="/" icon={lang === 2 ? "right arrow" : "left arrow"} />
        </TopNav>
        <h1>{t("Edit Card")}</h1>
        <S.Form>
          <Form
            onSubmit={onSubmit}
            render={({ handleSubmit, submitting }) => (
              <form onSubmit={handleSubmit}>
                <div className="input-field">
                  <h3>
                    {t("Card type")}:{" "}
                    {cardsStore.cards.find((c) => c.id === card.type)?.name ||
                      ""}
                  </h3>
                </div>
                <div className="input-field">
                  <label>{t("Card number")}</label>
                  <Field
                    name="number"
                    component="input"
                    type="text"
                    placeholder={t("Card number")}
                    initialValue={card.number}
                    disabled={submitting}
                  />
                </div>

                <div className="input-field">
                  <label>CVV</label>
                  <Field
                    name="cvv"
                    component="input"
                    type="text"
                    placeholder="CVV"
                    initialValue={card.cvv}
                    disabled={submitting}
                  />
                </div>

                <div className="input-field">
                  <label>{t("Expire date")}</label>
                  <Field
                    name="expire"
                    component="input"
                    type="date"
                    initialValue={card.expire}
                    disabled={submitting}
                  />
                </div>

                <div className="input-field">
                  <label>{t("Amount")}</label>
                  <Field
                    name="amount"
                    component="input"
                    type="number"
                    placeholder={t("Amount")}
                    initialValue={
                      (Array.isArray(card.balance) &&
                        card.balance.at(-1)?.amount) ||
                      0
                    }
                    disabled={submitting}
                  />
                </div>

                <div className="submit-button">
                  <button type="submit" disabled={submitting}>
                    {submitting ? (
                      <Loader active inverted inline size="small" />
                    ) : (
                      <span>{t("Update")}</span>
                    )}
                  </button>
                </div>
              </form>
            )}
          />
        </S.Form>
      </S.Wrapper>
    );
  }
  return null;
});
