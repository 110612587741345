import styled from 'styled-components';

export const Form = styled.div`
margin: 32px;

.submit-button {
    margin-top: 60px;
    button {
        background: linear-gradient(91.36deg, #7788DE 0%, #A3AFEC 100.18%);
        box-shadow: 0px 4px 16px rgba(37, 46, 88, 0.15);
        border-radius: 32px;
        padding: 8px;
        width: 300px;
        height: 44px;
        font-weight: 800;
font-size: 20px;
line-height: 28px;
text-align: center;
cursor: pointer;
color: #FFFFFF;
    }
    button:hover {
        opacity: 0.8;
    }
    
}

.error {
    text-align: center;
    color: #de2f2f;
}
`;