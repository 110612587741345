import styled from 'styled-components';

export const Wrapper = styled.div`
display: flex;
flex-direction: column;    
align-items: flex-start;
justify-content: center;
gap: 12px;
padding: 16px;
background: white;
width: 380px;
border-radius: 16px;
color: #252E58;
transition: 0.4s;

&.open {
    z-index: 3;
    box-shadow: 0px 4px 16px #3644881f;
}

.card-header{
    display: flex;
    align-items: center;
    gap: 12px;    
    justify-content: space-between;
    width: 100%;

    .card-title {
        font-weight: 400;
        font-size: 20px;
        line-height: 28px;
        margin: 0px;
    }

    .card-actions{
        display: flex;
        align-items: center;
        padding-inline-start: 12px;
        
        .ui.button {
            &.open {
                padding: 0px 4px 6px;
            }
            &.close {
                padding: 4px 4px 0px;
            }
            background: transparent;
            border-inline-start: 1px solid #d1d7f3;
            padding-inline-start: 12px;
            margin-inline-start: 4px;
            height: 24px;
        }
        i.close {
            transform: rotate(180deg) translateY(-6px);
        }


    }
}


.card-fields {
    display: flex;
    flex-direction: row;
    align-items: center;
    width: 100%;
    gap: 24px;
    justify-content: space-between;
    transition: 0.4s;

    &.number {
        height: 80px;
        margin-bottom: 20px;
    }

    &.pay {
        height: 48px;
    }

    &.hidden {
        height: 0px;
        opacity: 0;
        overflow: hidden;
    }

    &>div {
        display: flex;
        flex-direction: row;
        align-items: center;
        gap: 24px;
    }

    .options {
        border-top: 1px solid #d1d7f3;
        padding-top: 10px;
        margin-top: 4px;
        width: 100%;
        justify-content: space-evenly;
        .option {
            background: #4F5C9A;
            padding: 4px 16px;
            border-radius: 20px;
            font-weight: 600;
            font-size: 16px;
            line-height: 20px;
            color: #FFFFFF;
            transition: none;
            cursor: pointer;
            &:hover {
                background: #737eb1;
            }
        }
    }
    
    .use {
        border-top: 1px solid #d1d7f3;
        padding-top: 10px;
        margin-top: 4px;
        width: 100%;

        form {
            display: flex;
            input {
               background: #E3E6F8;
               border: 0px;
               border-radius: 0;
               padding: 8px 16px;
               border: 2px solid #E3E6F8;
               border-start-start-radius: 20px;
               border-end-start-radius: 20px;
               &:focus-visible{
                box-shadow: none !important;
                border-color: #7788DE;
               }
            }
            button {
                padding: 4px 16px;
                border-radius: 0;
                background: #7788DE;
                border: 0px;
                font-size: 20px;
                line-height: 23px;
                font-weight: 600;
                color: #FFFFFF;
                cursor: pointer;
                border: 2px solid #7788DE;
                border-end-end-radius: 20px;
                border-start-end-radius: 20px;
            }
        }
    }
}

.card-field {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    margin: 0px;
}



.card-field-value {
    font-weight: 600;
    font-size: 16px;
    line-height: 19px;
}

.card-number-value {
    font-weight: 600;
    font-size: 24px;
    line-height: 28px;
}

.card-field-label {
    font-weight: 400;
    font-size: 12px;
    line-height: 14px;
}

.card-number-label {
    font-weight: 400;
    font-size: 16px;
    line-height: 20px;
}

.important-actions {
    background: #d1d7f3;
    border-radius: 200px;
    padding: 6px 12px;
    a {
        padding: 0px;
        i {
            margin: 0px;
        }
    }
    
}
`;


export const ModalContent = styled.div`
padding: 32px;
    text-align: start;
div {
    display: flex;
    align-items: center;
    justify-content: center;
}
`;