import { TopNav } from "components/shared/TopNav";
import { useTranslation } from "react-i18next";
import { useStore } from "structure";
import { useNavigate } from "react-router-dom";
import { Form, Field } from "react-final-form";
import { Loader, Icon, Modal, Input, Button, Header } from "semantic-ui-react";
import * as S from "./style";
import { useState } from "react";
import { observer } from "mobx-react-lite";
import { CardTitle } from "components/shared/CardTitle";

const dateString = new Intl.DateTimeFormat("en-CA", {
  day: "2-digit",
  month: "2-digit",
  year: "numeric",
});

export const Add = observer(() => {
  const { storageStore, cardsStore, lang } = useStore();
  const { t } = useTranslation();
  const [selectedCard, setSelectedCard] = useState(0);
  const navigate = useNavigate();
  const [open, setOpen] = useState(false);

  let today = new Date();
  const defaultExpire = dateString.format(
    today.setFullYear(today.getFullYear() + 5)
  );

  const onSubmitNewCard = async (values) => {
    cardsStore.create(values);
    setOpen(false);
  };

  const onSubmit = async (values) => {
    storageStore.addCard({
      type: selectedCard,
      number: values.number,
      cvv: values.cvv,
      expire: values.expire,
      balance: [{ amount: values.amount, date: new Date() }],
    });
    navigate("/");
  };

  return (
    <S.Wrapper>
      <TopNav>
        <item to="/" icon={lang === 2 ? "right arrow" : "left arrow"} />
      </TopNav>
      <h1>{t("Add Card")}</h1>
      {selectedCard === 0 ? (
        <div>
          <h3>{t("Select card type")}</h3>
          <S.CardsType>
            {cardsStore.cards.map((card, index) => {
              if (card.name)
                return (
                  <S.CardType
                    key={card.id}
                    color={card.color || "#D9D9D9"}
                    onClick={() => setSelectedCard(card.id)}
                  >
                    <CardTitle cardType={card.id} />
                  </S.CardType>
                );
              return null;
            })}
            <S.AddCardType onClick={() => setOpen(true)}>
              <Icon name="add" />
            </S.AddCardType>
          </S.CardsType>
        </div>
      ) : (
        <S.Form>
          <Form
            onSubmit={onSubmit}
            render={({ handleSubmit, submitting }) => (
              <form onSubmit={handleSubmit}>
                <div className="input-field">
                  <h3>
                    {t("Card type")}:{" "}
                    {cardsStore.cards.find((card) => card.id === selectedCard)
                      ?.name || ""}
                  </h3>
                </div>
                <div className="input-field">
                  <label>{t("Card number")}</label>
                  <Field
                    name="number"
                    component="input"
                    type="text"
                    placeholder={t("Card number")}
                    disabled={submitting}
                  />
                </div>

                <div className="input-field">
                  <label>CVV</label>
                  <Field
                    name="cvv"
                    component="input"
                    type="text"
                    placeholder="CVV"
                    disabled={submitting}
                  />
                </div>

                <div className="input-field">
                  <label>{t("Expire date")}</label>
                  <Field
                    name="expire"
                    component="input"
                    type="date"
                    placeholder={t("Expire date")}
                    disabled={submitting}
                    defaultValue={defaultExpire}
                  />
                </div>

                <div className="input-field">
                  <label>{t("Amount")}</label>
                  <Field
                    name="amount"
                    component="input"
                    type="number"
                    placeholder={t("Amount")}
                    disabled={submitting}
                  />
                </div>

                <div className="submit-button">
                  <button type="submit" disabled={submitting}>
                    {submitting ? (
                      <Loader active inverted inline size="small" />
                    ) : (
                      <span>{t("Add this card")}</span>
                    )}
                  </button>
                </div>
              </form>
            )}
          />
        </S.Form>
      )}
      <Modal
        closeIcon
        open={open}
        size="tiny"
        onClose={() => setOpen(false)}
        onOpen={() => setOpen(true)}
      >
        <S.Modal>
          <Header
            icon="credit card outline"
            content={t("Create new card type")}
          />

          <Form
            onSubmit={onSubmitNewCard}
            render={({ handleSubmit, submitting }) => (
              <form onSubmit={handleSubmit}>
                <Modal.Content>
                  <div>
                    <label>{t("Card type name")}</label>
                    <Field
                      name="name"
                      placeholder={t("Card type name")}
                      disabled={submitting}
                    >
                      {(props) => <Input {...props.input} />}
                    </Field>
                  </div>

                  <div>
                    <label>{t("Card shops link")}</label>
                    <Field
                      name="stores_url"
                      placeholder="https..."
                      disabled={submitting}
                    >
                      {(props) => <Input {...props.input} />}
                    </Field>
                  </div>

                  <div>
                    <label>{t("Card balance link")}</label>
                    <Field
                      name="balance_url"
                      placeholder="https..."
                      disabled={submitting}
                    >
                      {(props) => <Input {...props.input} />}
                    </Field>
                  </div>

                  <div>
                    <label>{t("Card color")}</label>
                    <Field name="color" disabled={submitting}>
                      {(props) => <Input type="color" {...props.input} />}
                    </Field>
                  </div>
                </Modal.Content>
                <Modal.Actions>
                  <Button
                    className="cancel"
                    type="button"
                    onClick={() => setOpen(false)}
                  >
                    {t("Cancel")}
                  </Button>
                  <Button className="main" type="submit">
                    {t("Add new type")}
                  </Button>
                </Modal.Actions>
              </form>
            )}
          />
        </S.Modal>
      </Modal>
    </S.Wrapper>
  );
});
