import { Link } from "react-router-dom";
import { Icon } from "semantic-ui-react";
import * as S from "./style";

export const TopNav = ({ children }) => {
  const nav = Array.isArray(children) ? children : [children];

  return (
    <S.Wrapper>
      {nav.map((elem) => (
        <Link to={elem.props.to} key={`${elem.props.icon}-${elem.props.to}`}>
          <Icon name={elem.props.icon} size="large" />
        </Link>
      ))}
    </S.Wrapper>
  );
};
