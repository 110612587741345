import { useState } from "react";
import { useTranslation } from "react-i18next";
import { Form, Field } from "react-final-form";
import { useStore } from "structure";
import { Link } from "react-router-dom";
import { Loader } from "semantic-ui-react";
import { Icon } from "semantic-ui-react";
import { CardTitle } from "components/shared/CardTitle";
import * as S from "./style";

export const Card = ({ data }) => {
  const [isOpen, setIsOpen] = useState(false);
  const [isRestore, setIsRestore] = useState(false);
  const { storageStore } = useStore();
  const { t } = useTranslation();

  const onSubmit = async (values) => {
    if (values?.use) {
      const updatedCard = {
        ...data,
        balance: [
          ...data.balance,
          {
            amount: values?.use || 0,
            date: new Date(),
          },
        ],
      };
      storageStore.updateCard(data.id, updatedCard);
      setIsOpen(false);
      setIsRestore(false);
    }
  };

  return (
    <S.Wrapper className={isOpen ? "close" : "open"}>
      <div className="card-header">
        <h3 className="card-title">
          <CardTitle cardType={data.type} />
        </h3>
        <div className="card-actions">
          <Link onClick={() => setIsOpen(!isOpen)}>
            <Icon name={isOpen ? "close" : "chevron down"} />
          </Link>
        </div>
      </div>
      <div className={`card-fields ${isOpen ? "number" : "hidden"}`}>
        <div>
          <p className="card-field">
            <span className="card-number-value">{data.number}</span>
            <span className="card-number-label">{t("Card number")}</span>
          </p>
        </div>
        <div>
          <p className="card-field">
            <span className="card-field-value">{data.cvv}</span>
            <span className="card-field-label">CVV</span>
          </p>
        </div>
      </div>
      <div className="card-fields">
        <div>
          <p className="card-field">
            <span className="card-field-value">{data.expire}</span>
            <span className="card-field-label">{t("Expire date")}</span>
          </p>
        </div>
        <div className="important-actions">
          <Link
            to="#"
            onClick={() => {
              setIsRestore(!isRestore);
            }}
          >
            <Icon name="redo" />
            {t("Restore")}
          </Link>
        </div>
      </div>
      <div className={`card-fields ${isRestore ? "pay" : "hidden"}`}>
        <div className="use">
          <Form
            onSubmit={onSubmit}
            render={({ handleSubmit, submitting, values }) => {
              return (
                <>
                  <form onSubmit={handleSubmit}>
                    <Field
                      name="use"
                      component="input"
                      type="number"
                      disabled={submitting}
                    />
                    <button type="submit" disabled={submitting}>
                      {submitting ? (
                        <Loader active inverted inline size="small" />
                      ) : (
                        <span>{t("Set balance")}</span>
                      )}
                    </button>
                  </form>
                </>
              );
            }}
          />
        </div>
      </div>
    </S.Wrapper>
  );
};
