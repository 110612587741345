import { makeObservable, observable, computed, action } from "mobx";

export class View {
  openCards = []; 
  currentCloseCard = null;

    constructor(root) {
        this.rootStore = root;
        makeObservable(this, {
            openCards: observable.ref,
            currentCloseCard: observable.ref,
          isCardOpen: computed,
          toggleCardOpen: action,
          removeCardOpen: action,

        })
    }

    get isCardOpen() {
        return this.openCards.length > 0;
      }

    toggleCardOpen(id, closeCard) {
        if (!this.openCards.includes(id)) {
          this.openCards = [...this.openCards, id];
          this.currentCloseCard = closeCard;
        } else this.removeCardOpen(id);
    }
      
      removeCardOpen(id) {
        if (this.openCards.includes(id)) {
          this.openCards = this.openCards.filter(x => x !== id);
        }
      }
    
    

}