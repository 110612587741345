import styled from 'styled-components';

export const Wrapper = styled.div`
display: flex;
flex-direction: column;
align-items: center;
justify-content: center;
gap: 20px;

h1 {
    font-weight: 800;
    font-size: 36px;
    line-height: 42px;
    color: #364488;
}
`;

export const Form = styled.div`
margin: 32px;

.submit-button {
    margin-top: 60px;
    button {
        background: linear-gradient(91.36deg, #7788DE 0%, #A3AFEC 100.18%);
        box-shadow: 0px 4px 16px rgba(37, 46, 88, 0.15);
        border-radius: 32px;
        padding: 8px;
        width: 300px;
        height: 44px;
        font-weight: 800;
font-size: 20px;
line-height: 28px;
text-align: center;
cursor: pointer;
color: #FFFFFF;
    }
    button:hover {
        opacity: 0.8;
    }
}

.exit-button {
    margin-top: 20px;
    button {
        background: linear-gradient(91.36deg,#de7793 0%,#eca3a3 100.18%);
        box-shadow: 0px 4px 16px rgba(37, 46, 88, 0.15);
        border-radius: 32px;
        padding: 8px;
        width: 300px;
        height: 44px;
        font-weight: 800;
font-size: 20px;
line-height: 28px;
text-align: center;
cursor: pointer;
color: #FFFFFF;
    }
    button:hover {
        opacity: 0.8;
    }
}
`;

export const CardsType = styled.div`
max-width: 416px;
width: 100%;
display: flex;
justify-content: flex-start;
flex-direction: row;
flex-wrap: wrap;
gap: 16px;
`;


export const CardType = styled.button`
${({ color }) => `background: ${color};`}
aspect-ratio: 16 / 10;
width: calc(50% - 8px);
border-radius: 8px;
display: flex;
align-items: center;
justify-content: center;
color: #4D4D4D;
font-size: 24px;
font-weight: 700;
cursor: pointer;
`;