import { useStore } from "structure";
import { useTranslation } from "react-i18next";
import { Form, Field } from "react-final-form";
import { Loader } from "semantic-ui-react";
import { Link } from "react-router-dom";
import { FORM_ERROR } from "final-form";

import * as S from "./style";

export const Login = () => {
  const { accountStore } = useStore();
  const { t } = useTranslation();

  const onSubmit = async (values) => {
    try {
      await accountStore.login(values);
    } catch (e) {
      return { [FORM_ERROR]: t("Email and password not match") };
    }
  };

  return (
    <>
      <h1>{t("Login")}</h1>

      <S.Form>
        <Form
          onSubmit={onSubmit}
          render={({
            handleSubmit,
            submitting,
            modifiedSinceLastSubmit,
            submitError,
          }) => (
            <form onSubmit={handleSubmit}>
              <div className="input-field">
                <label>{t("Email")}</label>
                <Field
                  name="email"
                  component="input"
                  type="text"
                  required
                  placeholder={t("Email")}
                  disabled={submitting}
                />
              </div>
              <div className="input-field">
                <label>{t("Password")}</label>
                <Field
                  name="password"
                  component="input"
                  type="password"
                  required
                  placeholder={t("Password")}
                  disabled={submitting}
                />
              </div>
              {submitError && !modifiedSinceLastSubmit && (
                <p className="error">{submitError}</p>
              )}
              <div className="submit-button">
                <button type="submit" disabled={submitting}>
                  {submitting ? (
                    <Loader active inverted inline size="small" />
                  ) : (
                    <span>{t("Submit")}</span>
                  )}
                </button>
              </div>
            </form>
          )}
        />
      </S.Form>
      <p>
        {t("Don't have an account? go to")}{" "}
        <Link to="/register">{t("Register")}</Link>
      </p>
    </>
  );
};
