import { TopNav } from "../../shared/TopNav";
import { useTranslation } from "react-i18next";
import { Card } from "./Card";
import { useStore } from "structure";
import * as S from "./style";
import { useParams } from "react-router-dom";
import { observer } from "mobx-react-lite";

export const Home = observer(() => {
  const { storageStore, cardsStore, ViewStore } = useStore();
  const { t } = useTranslation();
  let { filter } = useParams();
  const cards = storageStore.storage.filter(
    (card) =>
      Array.isArray(card.balance) &&
      card.balance.at(-1)?.amount > 0 &&
      (!filter || Number(card.type) === Number(filter))
  );
  const closeCard = () => {
    return ViewStore.currentCloseCard ? ViewStore.currentCloseCard() : null;
  };

  const filterName = filter
    ? cardsStore.cards.find((card) => Number(card.id) === Number(filter))?.name
    : null;

  return (
    <S.Wrapper>
      <TopNav>
        <item to="/search" icon="search" />
        <item to="/add" icon="add" />
        <item to="/empty-cards" icon="trash" />
        <item to="/settings" icon="settings" />
      </TopNav>
      <S.Header>
        {filter ? (
          <h1>
            {t("Filter")}: {filterName}
          </h1>
        ) : (
          <h1>{t("All Cards")}</h1>
        )}
        <h2>
          {cards.length} {t("redults")}
        </h2>
      </S.Header>
      <div
        className={ViewStore.isCardOpen ? "dimmer active" : "dimmer"}
        onClick={ViewStore.isCardOpen ? closeCard : null}
      ></div>
      {cards.map((card) => (
        <Card key={card.id} data={card} />
      ))}
    </S.Wrapper>
  );
});
