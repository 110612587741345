import { useStore } from "./structure";
import { useTranslation } from "react-i18next";
import { BrowserRouter, Routes, Route } from "react-router-dom";
import * as S from "./style";
import { Enter } from "./components/Enter";
import { Layout } from "./components/Layout";

function App() {
  const { accountStore, lang } = useStore();
  const { t } = useTranslation();
  document.body.dir = lang === 2 ? "rtl" : "ltr";
  return (
    <S.Wrapper>
      <BrowserRouter>
        <Routes>
          <Route index element={<Layout page="home" />} />
          <Route path="login" element={<Enter />} />
          <Route path="register" element={<Enter page="register" />} />
          <Route path="add" element={<Layout page="add" />} />
          <Route path="edit-card/:cardId" element={<Layout page="edit" />} />
          <Route path="search" element={<Layout page="search" />} />
          <Route path="empty-cards" element={<Layout page="emptyCards" />} />
          <Route path="settings" element={<Layout page="settings" />} />
          <Route path="filter/:filter" element={<Layout page="home" />} />
          <Route path="*" element={<div>404</div>} />
        </Routes>
      </BrowserRouter>
      {accountStore.isAdmin && <S.AdminSign>{t("admin")}</S.AdminSign>}
    </S.Wrapper>
  );
}

export default App;
