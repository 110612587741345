import { TopNav } from "components/shared/TopNav";
import { useTranslation } from "react-i18next";
import { Card } from "./Card";
import { useStore } from "structure";
import * as S from "./style";
import { useParams } from "react-router-dom";
import { observer } from "mobx-react-lite";
import { useEffect, useState } from "react";

export const EmptyCards = observer(() => {
  const { storageStore, lang } = useStore();
  const { t } = useTranslation();
  let { filter } = useParams();
  const [cards, setCards] = useState(
    storageStore.storage.filter(
      (card) => Array.isArray(card.balance) && card.balance.at(-1)?.amount === 0
    )
  );

  useEffect(() => {
    setCards(
      storageStore.storage.filter(
        (card) =>
          Array.isArray(card.balance) && card.balance.at(-1)?.amount === 0
      )
    );
  }, [storageStore.storage]);

  return (
    <S.Wrapper>
      <TopNav>
        <item to="/" icon={lang === 2 ? "right arrow" : "left arrow"} />
      </TopNav>
      <S.Header>
        {filter ? (
          <h1>
            {t("Empty")} {filter} {t("Cards")}
          </h1>
        ) : (
          <h1>{t("Empty Cards")}</h1>
        )}
        <h2>
          {cards.length} {t("results")}
        </h2>
      </S.Header>

      {cards.map((card) => (
        <Card key={card.id} data={card} />
      ))}
    </S.Wrapper>
  );
});
