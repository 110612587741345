import { makeObservable, observable, action, runInAction } from "mobx";
import { storage } from "../endpoints";

function makeid(length) {
    let result = '';
    const characters = 'ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789';
    const charactersLength = characters.length;
    let counter = 0;
    while (counter < length) {
      result += characters.charAt(Math.floor(Math.random() * charactersLength));
      counter += 1;
    }
    return result;
}

export class Storage {
    storageId = 0;
    storage = [];
    settings = {};
    isLoading = false;

    constructor(root) {
        this.rootStore = root;
        makeObservable(this, {
            storageId: observable,
            storage: observable.ref,
            settings: observable.ref,
            isLoading: observable,
            setSettings: action,
            fetchStorage: action,
            addCard: action,
            updateCard: action,
            removeCard: action,
        })
    }

    async fetchStorage() {
        this.isLoading = true;
        const res = await storage.get();
        const data = res?.data?.[0];
        if (data?.id) {
            this.storageId = data?.id;
            const dataStorage = JSON.parse(data.data);
            this.storage = dataStorage.storage || data;
            this.settings = dataStorage.settings || {};
        } else {
            try {
                await storage.create({ data: JSON.stringify({ storage: [], settings: { lang: this.rootStore.lang, curr: this.rootStore.lang } }) });
            } catch (e) {}
            await storage.update({data: JSON.stringify({storage: [], settings: { lang: 1, curr: 1 }})});
            const res = await storage.get();
            const data = res?.data?.[0];
            if (data?.id) {
                this.storageId = data?.id;
                const dataStorage = JSON.parse(data.data);
                this.storage = dataStorage.storage || data;
                this.settings = dataStorage.settings || {};
            }
        }
        this.rootStore.changeLanguage(this.settings.lang);
        this.rootStore.changeCurrency(this.settings.curr);
        this.isLoading = false;
    }

     update() {
        return storage.update({data: JSON.stringify({storage: this.storage, settings: this.settings})});
    }

    
    setSettings(data) {
        runInAction(() => {
             this.settings = data;
        });
        
        this.rootStore.changeLanguage(this.settings.lang);
        this.rootStore.changeCurrency(this.settings.curr);
        return this.update();
    }

    getCardById(cardId) {
        return this.storage.find(card => card.id === cardId);
    }

    addCard(card) {
        runInAction(() => {
             this.storage = [...this.storage, {id: makeid(12), ...card}];
        });
        return this.update();
    }

    
    updateCard(cardId, card) {
        const cardIndex = this.storage.findIndex(card => card.id === cardId)
        if (cardIndex === -1) return false;
        
        runInAction(() => {
                   this.storage = [...this.storage.slice(0, cardIndex), { id: cardId, ...card }, ...this.storage.slice(cardIndex + 1)];
  
        });
        return this.update();
    }

    
    removeCard(cardId) {
        const cardIndex = this.storage.findIndex(card => card.id === cardId)
        if (cardIndex === -1) return false;
        
        runInAction(() => {
             this.storage = [...this.storage.slice(0, cardIndex), ...this.storage.slice(cardIndex + 1)];
        });
        
        return this.update();
    }

}