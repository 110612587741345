import { useStore } from "structure";

export const CardTitle = ({ cardType, size }) => {
  const { cardsStore } = useStore();
  const cardLogo = cardsStore.getCardLogoById(cardType);
  const cardName = cardsStore.getCardNameById(cardType);
  return cardLogo ? (
    <img src={cardLogo} alt={cardName} height={size ? size : 28} />
  ) : (
    cardName
  );
};
