import { makeObservable, observable, runInAction } from "mobx";
import { cards } from "../endpoints";

export class Cards {
    cards = [];
    isLoading = false;

    constructor(root) {
        this.rootStore = root;
        makeObservable(this, {
            cards: observable.ref,
            isLoading: observable,
        })
    }

    async fetchCards() {
        runInAction(() => {
            this.isLoading = true;
        });
        const res = await cards.get();
        if (res.data) {
            runInAction(() => {
                this.cards = res.data;
                this.isLoading = false;
            });
        } 
            
        runInAction(() => {
        this.isLoading = false;
        });
    }

    getCardLogoById(cardId) {
        return this.cards?.find(card => card.id === cardId)?.logo || null;
    }

    getCardNameById(cardId) {
        return this.cards?.find(card => card.id === cardId)?.name || "unknown";
    }

    async create(data) {
        const res = await cards.create(data);
        this.fetchCards();
        return res;
    }

    async update(id, data) {
        const res = await cards.update(id, data);
        this.fetchCards();
        return res;
    }

    async delete(id) {
        const res = await cards.delete(id);
        this.fetchCards();
        return res;
    }

}