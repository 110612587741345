import { Button, Modal, Popup, Table, Dropdown, Icon } from "semantic-ui-react";
import { useNavigate } from "react-router-dom";
import { useTranslation } from "react-i18next";
import { useState } from "react";
import { useStore } from "structure";
import { CardOptionsWrapper, DropdownWrapper } from "./style";

const openInNewTab = (url) => {
  window.open(url, "_blank", "noreferrer");
};

const dateString = new Intl.DateTimeFormat("en-GB", {
  day: "2-digit",
  month: "2-digit",
  year: "numeric",
  hour: "2-digit",
  minute: "2-digit",
});

export const CardOptions = ({ id }) => {
  const { storageStore, cardsStore } = useStore();
  const { t } = useTranslation();
  const navigate = useNavigate();
  const [openPopup, setOpenPopup] = useState(false);
  const [openHistoryModal, setOpenHistoryModal] = useState(false);
  const card = storageStore.getCardById(id) || {};
  const cardType = cardsStore.cards.find((c) => c.id === card.type) || {};
  const historyList = card.balance.reverse();

  const options = [
    {
      text: t("Shops list"),
      icon: "shop",
      onClick: (e) => {
        e.stopPropagation();
        setOpenPopup(false);
        return cardType.stores_url ? openInNewTab(cardType.stores_url) : null;
      },
      disabled: !cardType.stores_url,
    },
    {
      text: t("Check balance"),
      icon: "payment",
      onClick: (e) => {
        e.stopPropagation();
        setOpenPopup(false);
        return cardType.balance_url ? openInNewTab(cardType.balance_url) : null;
      },
      disabled: !cardType.balance_url,
    },
    {
      text: t("Edit card"),
      icon: "edit",
      onClick: (e) => {
        e.stopPropagation();
        setOpenPopup(false);
        return navigate(`/edit-card/${id}`);
      },
    },
    {
      text: t("View history"),
      icon: "history",
      onClick: (e) => {
        e.stopPropagation();
        setOpenPopup(false);
        return setOpenHistoryModal(true);
      },
    },
  ];

  return (
    <CardOptionsWrapper>
      <Popup
        trigger={
          <button
            className="action-icon"
            type="button"
            aria-label="button"
            onClick={(e) => e.stopPropagation()}
          >
            <Icon name="ellipsis vertical" size="small" />
          </button>
        }
        content={
          <DropdownWrapper>
            <Dropdown.Menu>
              {options.map((option, idx) => (
                <Dropdown.Item key={idx} {...option} />
              ))}
            </Dropdown.Menu>
          </DropdownWrapper>
        }
        on="click"
        open={openPopup}
        onClose={() => setOpenPopup(false)}
        onOpen={() => setOpenPopup(true)}
        hideOnScroll
      />
      <Modal
        open={openHistoryModal}
        size="tiny"
        onClose={() => setOpenHistoryModal(false)}
      >
        <Modal.Header>{t("History")}</Modal.Header>
        <Modal.Content scrolling>
          <Table>
            <Table.Header>
              <Table.Row>
                <Table.HeaderCell>{t("Date")}</Table.HeaderCell>
                <Table.HeaderCell>{t("Balance")}</Table.HeaderCell>
              </Table.Row>
            </Table.Header>

            <Table.Body>
              {historyList.map((row, idx) => (
                <Table.Row key={`${id}-${idx}`}>
                  <Table.Cell>
                    {dateString.format(new Date(row.date))}
                  </Table.Cell>
                  <Table.Cell>${row.amount}</Table.Cell>
                </Table.Row>
              ))}
            </Table.Body>
          </Table>
        </Modal.Content>
        <Modal.Actions>
          <Button onClick={() => setOpenHistoryModal(false)} primary>
            {t("Close")}
          </Button>
        </Modal.Actions>
      </Modal>
    </CardOptionsWrapper>
  );
};
