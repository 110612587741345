import styled from 'styled-components';

export const Wrapper = styled.div`
display: flex;
flex-direction: row;
align-items: center;
width: 100%;
justify-content: flex-start;
gap: 32px;
&>a {
    width: 64px;
    height: 64px;
    display: flex;
    align-items: center;
    justify-content: center;
    background: #D1D7F3 !important;
    box-shadow: 4px 4px 16px rgba(37, 46, 88, 0.12), -4px -4px 16px rgba(255, 255, 255, 0.24);
    border-radius: 100px;
    transition: 0.4s;
    .icon {
        margin: 0px !important;
    }
    &:hover {
    background: #e1e5fc !important;
    }
}
`;

export const CardOptionsWrapper = styled.div`
.action-icon {
    background: none;
    border: none;
    height: 16px;
    cursor: pointer;
    padding: 2px;
    >i {
        color: #364488;
    }
}
`;

export const DropdownWrapper = styled.div`
.item {
    padding: 8px;
    border-radius: 4px;
    cursor: pointer;
    color: #364488;
    transition: 0.4s;
    >i {
        margin-right: 8px;
    }
    :hover {
        background: #f2f4ff ;
    }

    &.disabled {
        opacity: 0.4;
    cursor: default;

        :hover {
            background: none;
        }
    }
    
}
`;