import { Account } from "./stores/account";
import { makeObservable, observable, action } from "mobx";
import { Cards } from "./stores/cards";
import { View } from "./stores/view";
import { Storage } from "./stores/storage";
import i18n from "i18next";

class RootStore {
  isFirstLoading = true;
  
  lang = 1;
  currSign = "$";

    constructor() {
      this.accountStore = new Account(this);
      this.cardsStore = new Cards(this);
      this.storageStore = new Storage(this);
      this.ViewStore = new View(this);

      //fetch first loading
      this.fitstLoading();

      makeObservable(this, {
        isFirstLoading: observable,
        lang: observable,
        currSign: observable,
        fitstLoading: action,
        changeCurrency: action, 
        changeLanguage: action,
    })
  }

  
  changeLanguage(lang) {
    if (lang)
    {
      this.lang = lang;
      i18n.changeLanguage(lang === 2 ? "he" : "en");
    }
  }

  changeCurrency(curr) {
    if (curr)
    {
      this.currSign = curr === 2 ? "₪" : "$";
    }
  }
  
  async fitstLoading() {
    fetch("https://ipapi.co/json/")
      .then((res) => res.json())
      .then((response) => {
        if (response.country === "IL") this.changeLanguage(2);
      });
    await Promise.all([this.cardsStore.fetchCards(), this.storageStore.fetchStorage()]);
    this.isFirstLoading = false;
  }
}    
const rootStore = new RootStore();
export const useStore = () => {
  return rootStore;
}