import { useStore } from "../structure";
import { useNavigate } from "react-router-dom";
import { Login } from "./EnterLayouts/Login";
import { Register } from "./EnterLayouts/Register";
import Logo from "../assets/logo.png";
import * as S from "./style";
import { useEffect } from "react";
import { observer } from "mobx-react-lite";

export const Enter = observer(({ page = "login" }) => {
  const { accountStore } = useStore();
  const navigate = useNavigate();

  useEffect(() => {
    if (accountStore.isAuthanticated) {
      navigate("/");
    }
    // eslint-disable-next-line
  }, [accountStore.isAuthanticated]);

  return (
    <S.Wrapper>
      <img src={Logo} className="logo" alt="cardi" />
      {page === "login" && <Login />}
      {page === "register" && <Register />}
    </S.Wrapper>
  );
});
