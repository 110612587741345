
const BASE_URL_API = "https://api.cardi-app.com/api/"

const createHeaders = () => {
    const token = localStorage.getItem('token');
    return ({
        'Content-Type': 'application/json',
        'Accept': 'application/json',
        ...(token ? { 'Authorization': `Bearer ${token}` } : {})
    });
}

const GET = (endpoint, params) => {
    let paramsUri = "";
    
    if (params) {
        paramsUri = "?" + Object.entries(params).map(([key, value]) => encodeURIComponent(`${key}=${value}`)).join("&");
    }
    return fetch(`${BASE_URL_API}${endpoint}/${paramsUri}`,{
        method: 'GET',
        headers: createHeaders()
    }).then(response => response.json());
}

const POST = (endpoint, body = {}) => {
    return fetch(`${BASE_URL_API}${endpoint}`, {
        method: 'POST',
        headers: createHeaders(),
        body: JSON.stringify(body)
    }).then(response => response.json()).then(response => {
        if (response.status === "success" || typeof response.id === "number") {
            return response.data;
        } else {
            console.error(response);
        }
    });
}
// eslint-disable-next-line
const PUT = (endpoint, id, body) => {
    return fetch(`${BASE_URL_API}${endpoint}/${id}`, {
        method: 'PUT',
        headers: createHeaders(),
        body: JSON.stringify(body)
    }).then(response => response.json()).then(response => {
        if (response.status === "success") {
            return response.data;
        } else {
            console.error(response);
        }
    });
}
// eslint-disable-next-line
const DELETE = (endpoint, id) => {
    return fetch(`${BASE_URL_API}${endpoint}/${id}`, {
        method: 'DELETE',
        headers: createHeaders(),
    });
}

export const auth = {
    login: (data) => {
        return POST('login', data);
    },
    
    register: (data) => {
        return POST('register', data);
    },

    logout: () => {
        return POST('logout');
    },
}

export const storage = {
    get: (params) => {
        return GET('storages', params);
    },
    
    update: (data) => {
        return POST('storages', data);
    },
}


export const cards = {
    get: (params) => {
        return GET('cards', params);
    },
    
    create: (data) => {
        return POST('cards', data);
    },
    
    update: (id, data) => {
        return PUT('cards', id, data);
    },
    
    delete: (id) => {
        return DELETE('cards', id);
    },
}