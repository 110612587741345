import { useStore } from "structure";
import { useTranslation } from "react-i18next";
import { Form, Field } from "react-final-form";
import { Loader } from "semantic-ui-react";
import { Link } from "react-router-dom";
import * as S from "./style";

export const Register = () => {
  const { accountStore } = useStore();
  const { t } = useTranslation();

  const onSubmit = async (values) => {
    await accountStore.register(values);
  };

  return (
    <>
      <h1>{t("Register")}</h1>

      <S.Form>
        <Form
          onSubmit={onSubmit}
          render={({ handleSubmit, submitting }) => (
            <form onSubmit={handleSubmit} disabled={submitting}>
              <div className="input-field">
                <label>{t("Name")}</label>
                <Field
                  name="name"
                  component="input"
                  type="text"
                  placeholder={t("Name")}
                  required
                  disabled={submitting}
                />
              </div>
              <div className="input-field">
                <label>{t("Email")}</label>
                <Field
                  name="email"
                  component="input"
                  type="email"
                  required
                  placeholder={t("Email")}
                  disabled={submitting}
                />
              </div>
              <div className="input-field">
                <label>{t("Password")}</label>
                <Field
                  name="password"
                  component="input"
                  type="password"
                  required
                  placeholder={t("Password")}
                  disabled={submitting}
                />
              </div>
              <div className="input-field">
                <label>{t("Password Confirmation")}</label>
                <Field
                  name="password_confirmation"
                  component="input"
                  type="password"
                  required
                  placeholder={t("Password Confirmation")}
                  disabled={submitting}
                />
              </div>
              <div className="submit-button">
                <button type="submit" disabled={submitting}>
                  {submitting ? (
                    <Loader active inverted inline size="small" />
                  ) : (
                    <span>{t("Register")}</span>
                  )}
                </button>
              </div>
            </form>
          )}
        />
      </S.Form>
      <p>
        {t("I have an account, go to")}
        <Link to="/login">{t("Login")}</Link>
      </p>
    </>
  );
};
