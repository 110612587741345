import { useStore } from "structure";
import { observer } from "mobx-react-lite";
import { useNavigate } from "react-router-dom";
import Logo from "assets/logo.png";
import { Home } from "components/Layouts/Home";
import { Add } from "components/Layouts/Add";
import { Edit } from "components/Layouts/Edit";
import { Search } from "components/Layouts/Search";
import { Settings } from "components/Layouts/Settings";
import { Loader } from "semantic-ui-react";
import { useEffect } from "react";
import * as S from "./style";
import { EmptyCards } from "components/Layouts/EmptyCards";

export const Layout = observer(({ page = "home", ...props }) => {
  const { accountStore, isFirstLoading } = useStore();
  const navigate = useNavigate();

  useEffect(() => {
    if (!accountStore.isAuthanticated) {
      navigate("/login");
    }
    // eslint-disable-next-line
  }, [accountStore.isAuthanticated]);

  if (isFirstLoading)
    return (
      <S.FullPage>
        <Loader active inline="centered" />
      </S.FullPage>
    );

  return (
    <S.LayoutWrapper>
      <img src={Logo} className="logo" alt="cardi" />
      {page === "home" && <Home filter={props.filter} />}
      {page === "add" && <Add />}
      {page === "edit" && <Edit cardId={props.cardId} />}
      {page === "search" && <Search />}
      {page === "emptyCards" && <EmptyCards />}
      {page === "settings" && <Settings />}
    </S.LayoutWrapper>
  );
});
