import { TopNav } from "components/shared/TopNav";
import { useTranslation } from "react-i18next";
import { useStore } from "structure";
import { Form, Field } from "react-final-form";
import { Input, Icon, Button, Header, Modal } from "semantic-ui-react";
import { CardTitle } from "components/shared/CardTitle";

import * as S from "./style";
import { useState } from "react";
import { observer } from "mobx-react-lite";

export const Search = observer(() => {
  const { cardsStore, lang } = useStore();
  const { t } = useTranslation();
  const [open, setOpen] = useState(false);
  const [cards, setCards] = useState(cardsStore.cards);

  const onSubmitNewCard = async (values) => {
    cardsStore.create(values);
    setOpen(false);
  };

  const onSearch = async (e, data) => {
    setCards(
      data?.value
        ? cardsStore.cards.filter((card) =>
            card.name.toLowerCase().includes(data.value.toLowerCase())
          )
        : cardsStore.cards
    );
  };

  return (
    <S.Wrapper>
      <TopNav>
        <item to="/" icon={lang === 2 ? "right arrow" : "left arrow"} />
      </TopNav>
      <h1>{t("Search")}</h1>
      <S.Form>
        <Input
          action={{ icon: "search" }}
          placeholder={t("Search") + "..."}
          onChange={onSearch}
        />
      </S.Form>
      <S.CardsType>
        {cards.map((card) => {
          if (card.name)
            return (
              <S.CardType
                key={card.id}
                color={card.color || "#70727d"}
                href={`/filter/${card.id}`}
                //add link to card id filter
              >
                <CardTitle cardType={card.id} size={40} />
              </S.CardType>
            );
          return null;
        })}
        <S.AddCardType onClick={() => setOpen(true)}>
          <Icon name="add" />
        </S.AddCardType>
      </S.CardsType>

      <Modal
        closeIcon
        open={open}
        size="tiny"
        onClose={() => setOpen(false)}
        onOpen={() => setOpen(true)}
      >
        <S.Modal>
          <Header
            icon="credit card outline"
            content={t("Create new card type")}
          />

          <Form
            onSubmit={onSubmitNewCard}
            render={({ handleSubmit, submitting }) => (
              <form onSubmit={handleSubmit}>
                <Modal.Content>
                  <div>
                    <label>{t("Card type name")}</label>
                    <Field
                      name="name"
                      placeholder={t("Card type name")}
                      disabled={submitting}
                    >
                      {(props) => <Input {...props.input} />}
                    </Field>
                  </div>

                  <div>
                    <label>{t("Card shops link")}</label>
                    <Field
                      name="stores_url"
                      placeholder="https..."
                      disabled={submitting}
                    >
                      {(props) => <Input {...props.input} />}
                    </Field>
                  </div>

                  <div>
                    <label>{t("Card balance link")}</label>
                    <Field
                      name="balance_url"
                      placeholder="https..."
                      disabled={submitting}
                    >
                      {(props) => <Input {...props.input} />}
                    </Field>
                  </div>

                  <div>
                    <label>{t("Card color")}</label>
                    <Field name="color" disabled={submitting}>
                      {(props) => <Input type="color" {...props.input} />}
                    </Field>
                  </div>
                </Modal.Content>
                <Modal.Actions>
                  <Button
                    className="cancel"
                    type="button"
                    onClick={() => setOpen(false)}
                  >
                    {t("Cancel")}
                  </Button>
                  <Button className="main" type="submit">
                    {t("Add new type")}
                  </Button>
                </Modal.Actions>
              </form>
            )}
          />
        </S.Modal>
      </Modal>
    </S.Wrapper>
  );
});
