import { useState } from "react";
import { useTranslation } from "react-i18next";
import { observer } from "mobx-react-lite";
import { Form, Field } from "react-final-form";
import { useStore } from "structure";
import { Link } from "react-router-dom";
import { Loader, Modal, Icon, Button } from "semantic-ui-react";
import { CardOptions } from "components/shared/CardOptions";
import { CardTitle } from "components/shared/CardTitle";
import * as S from "./style";

export const Card = observer(({ data }) => {
  const [isOpen, setIsOpen] = useState(false);
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [isPay, setIsPay] = useState(false);
  const [isUse, setIsUse] = useState(false);
  const { t } = useTranslation();
  const { storageStore, currSign, ViewStore } = useStore();
  const [currentBalance, setCurrentBalance] = useState(
    calcBalance(data.balance)
  );

  const openCard = () => {
    if (!isOpen) {
      setIsOpen(true);
      ViewStore.toggleCardOpen(data.id, closeCard);
    }
  };

  const closeCard = () => {
    setIsOpen(false);
    ViewStore.removeCardOpen(data.id);
  };

  const onSubmit = async (values) => {
    if (values?.use) {
      const amount = calcBalanceLeft(currentBalance, values?.use);
      const updatedCard = {
        ...data,
        balance: [
          ...data.balance,
          {
            amount: amount,
            date: new Date(),
          },
        ],
      };
      storageStore.updateCard(data.id, updatedCard);
      setCurrentBalance(amount);
      closeCard();
      setIsPay(false);
      setIsUse(false);
      setIsModalOpen(false);
    }
  };

  return (
    <S.Wrapper className={isOpen ? "open" : "close"} onClick={openCard}>
      <div className="card-header" onClick={isOpen ? closeCard : null}>
        <h3 className="card-title">
          <CardTitle cardType={data.type} />
        </h3>
        <div className="card-actions">
          <CardOptions id={data.id} />

          <Button onClick={closeCard} className={isOpen ? "open" : "close"}>
            <Icon name={isOpen ? "close" : "chevron down"} />
          </Button>
        </div>
      </div>
      <div className={`card-fields ${isOpen ? "number" : "hidden"}`}>
        <div>
          <p className="card-field">
            <span className="card-number-value">{data.number}</span>
            <span className="card-number-label">{t("Card number")}</span>
          </p>
        </div>
        <div>
          <p className="card-field">
            <span className="card-field-value">{data.cvv}</span>
            <span className="card-field-label">CVV</span>
          </p>
        </div>
      </div>
      <div className="card-fields">
        <div>
          <p className="card-field">
            <span className="card-field-value">
              {currSign}
              {currentBalance}
            </span>
            <span className="card-field-label">{t("Balance")}</span>
          </p>
          <p className="card-field">
            <span className="card-field-value">{data.expire}</span>
            <span className="card-field-label">{t("Expire date")}</span>
          </p>
        </div>
        <div className="important-actions">
          <Link
            to="#"
            onClick={(e) => {
              e.stopPropagation();
              setIsPay(!isPay);
              setTimeout(() => {
                setIsUse(false);
              }, 400);
            }}
          >
            <Icon name="usd" />
            {t("Pay")}
          </Link>
        </div>
      </div>
      <div
        className={`card-fields ${isPay ? "pay" : "hidden"}`}
        onClick={(e) => e.stopPropagation()}
      >
        {isUse ? (
          <div className="use">
            <Form
              onSubmit={onSubmit}
              render={({ handleSubmit, submitting, values }) => {
                return (
                  <>
                    <form onSubmit={handleSubmit}>
                      <Field
                        name="use"
                        component="input"
                        type="number"
                        disabled={submitting}
                      />
                      <button type="submit" disabled={submitting}>
                        {submitting ? (
                          <Loader active inverted inline size="small" />
                        ) : (
                          <span>{t("Use")}</span>
                        )}
                      </button>
                    </form>
                    <p className="card-field">
                      <span className="card-field-value">
                        {currSign}
                        {calcBalanceLeft(currentBalance, values?.use)}
                      </span>
                      <span className="card-field-label">
                        {t("Balance left")}
                      </span>
                    </p>
                  </>
                );
              }}
            />
          </div>
        ) : (
          <div className="options">
            <button className="option" onClick={() => setIsUse(!isUse)}>
              {t("Partial use")}
            </button>
            <Modal
              onClose={() => setIsModalOpen(false)}
              onOpen={() => setIsModalOpen(true)}
              open={isModalOpen}
              size="tiny"
              trigger={<button className="option">{t("Full use")}</button>}
            >
              <S.ModalContent>
                <p>
                  {t(
                    "Card will move to used card list, are you sure you want to set the card as full used?"
                  )}
                </p>
                <div className="actions">
                  <Button
                    primary
                    onClick={() => onSubmit({ use: currentBalance })}
                  >
                    {t("Yes")}
                  </Button>
                  <Button basic onClick={() => setIsModalOpen(false)}>
                    {t("Not yet")}
                  </Button>
                </div>
              </S.ModalContent>
            </Modal>
          </div>
        )}
      </div>
    </S.Wrapper>
  );
});

const calcBalance = (balanceArr) =>
  Array.isArray(balanceArr) ? balanceArr.at(-1)?.amount || 0 : 0;

const calcBalanceLeft = (currentBalance, amountUse) =>
  Math.max(currentBalance - (amountUse || 0), 0);
