import styled from 'styled-components';

export const Wrapper = styled.div`
display: flex;
flex-direction: column;
align-items: center;
justify-content: center;
gap: 20px;
`;

export const Header = styled.div`
margin: 20px auto;
display: flex;
    flex-direction: column;
    align-items: center;
h1 {
    font-weight: 800;
    font-size: 36px;
    line-height: 42px;
    color: #364488;
    margin-bottom: 0;
}
h2 {
    margin-top: 0;
    font-weight: 400;
    font-size: 24px;
    line-height: 30px;
}
`;