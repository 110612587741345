import { makeObservable, observable, action, computed  } from "mobx";
import { auth } from "../endpoints";

export class Account {
    currentAccount = null;
    isLoading = false;

    constructor() {
        if (localStorage.getItem('account') && localStorage.getItem('token')) {
            this.currentAccount = JSON.parse(localStorage.getItem('account'));
        }
        makeObservable(this, {
            currentAccount: observable,
            isLoading: observable,
            isAdmin: computed,
            login: action,
            register: action,
            logout: action
        })
    }

    get isAdmin () {
        return this.currentAccount?.id === 1;
    }

    
    get isAuthanticated () {
        return this.currentAccount !== null;
    }

    async login({ email, password, remember = true }) {
        this.isLoading = true;
        const res = await auth.login({
            email, password
        });
        if (res.user) {
            this.currentAccount = res.user;
            localStorage.setItem('token', res.token);
            if (remember) localStorage.setItem('account', JSON.stringify(res.user));
            this.isLoading = false;
            return true;
        } 
        this.isLoading = false;
        return false;
    }

    async register({name, email, password, password_confirmation }) {
        this.isLoading = true;
        const res = await auth.register({
            name, email, password, password_confirmation 
        });
        if (res.user) {
            this.currentAccount = res.user;
            localStorage.setItem('token', res.token);
            this.isLoading = false;
            return true;
        } 
        this.isLoading = false;
        return false;
    }

    async logout() {
        this.isLoading = true;
        await auth.logout();
        localStorage.removeItem('account');
        localStorage.removeItem('token');
        this.currentAccount = null;
        this.isLoading = false;
        return true;
    }
}