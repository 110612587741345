import styled from 'styled-components';

export const Wrapper = styled.div`
width: 100vw;
min-height: 100vh;
display: flex;
flex-direction: column;
align-items: center;
justify-content: flex-start;
background: linear-gradient(347.98deg, #FFFFFF 0%, #EFF1FC 100%);
color: #252E58;


.input-field {
    display: flex;
    flex-direction: column;
    gap: 8px;
    margin-bottom: 20px;
    label {
        font-weight: 400;
        font-size: 16px;
        line-height: 20px;
        color: #252E58;
    }
    input {
        width: 300px;
        padding: 12px;
        font-weight: 500;
        font-size: 16px;
        line-height: 20px;
        background: #FFFFFF;
        box-shadow: 0px 4px 16px rgba(0, 0, 0, 0.05);
        border-radius: 4px;
        border: none;
        color: #252E58;
    }
}

a, .as-link {
    cursor: pointer;
    background: transparent;
    border: none;
    font-weight: 600;
    padding: 0 4px;
    color: #364488;
    &:hover {
        color: #445ac0;
    }
    img {
        max-width: 95%;
    }
}
`;

export const AdminSign = styled.p`
padding: 4px 8px;
width: 100%;
background: #ffffffb5;
color: gray;
text-align: center;
`;