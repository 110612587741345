import styled from 'styled-components';

export const Wrapper = styled.div`
display: flex;
flex-direction: column;
align-items: center;
justify-content: center;
gap: 20px;

h1 {
    font-weight: 800;
    font-size: 36px;
    line-height: 42px;
    color: #364488;
}

`;

export const Form = styled.div`
margin: 32px;

.ui.input {
    border-radius: 100px;
    overflow: hidden;
    width: 332px;
    input {
        border: none;
        font-size: 16px;
        text-align: start;
    }
}

.ui.action.input>.button {
    background: white;
}

.ui.icon.button>.icon {
    color: #364488;
}
`;

export const CardsType = styled.div`
max-width: 416px;
width: 100%;
display: flex;
justify-content: flex-start;
flex-direction: row;
flex-wrap: wrap;
gap: 16px;
`;


export const CardType = styled.a`
${({ color }) => `background: ${color} !important;`}
aspect-ratio: 16 / 10;
width: calc(50% - 8px);
border-radius: 8px;
display: flex;
align-items: center;
justify-content: center;
color: white !important;
font-size: 24px;
font-weight: 700;
cursor: default;
`;

export const AddCardType = styled.button`
background: white;
aspect-ratio: 16 / 10;
width: calc(50% - 8px);
border-radius: 8px;
display: flex;
align-items: center;
justify-content: center;
box-shadow: 0px 0px 20px #c1caff;
cursor: pointer;
`;

export const Modal = styled.div`
padding: 0 16px 16px;
.content{
    display: flex;
    flex-direction: column;
    align-items: flex-start
    justify-content: center;
    gap: 16px;
    padding: 24px 0;

    &>div {
        display: flex;
        flex-direction: column;
    }
}
.actions {
    display: flex;
    justify-content: flex-end;
}
.ui.main.button {
    background-color: #364488;
    color: white;
}
.ui.cancel.button {
    background-color: transparent;
    color: #4D4D4D;
}
`;