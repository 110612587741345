import { TopNav } from "components/shared/TopNav";
import { useTranslation } from "react-i18next";
import { useStore } from "structure";
import { useNavigate } from "react-router-dom";
import { Form, Field } from "react-final-form";
import { Dropdown, Loader } from "semantic-ui-react";
import * as S from "./style";
import { observer } from "mobx-react-lite";

export const Settings = observer(() => {
  const { storageStore, accountStore, lang } = useStore();
  const { t } = useTranslation();
  const settings = storageStore.settings;
  const navigate = useNavigate();
  const langOptions = [
    {
      key: 1,
      text: "English",
      value: 1,
    },
    {
      key: 2,
      text: "עברית",
      value: 2,
    },
  ];

  const currOptions = [
    {
      key: 1,
      text: "$ " + t("USD"),
      value: 1,
    },
    {
      key: 2,
      text: "₪ " + t("ILS"),
      value: 2,
    },
  ];

  const onSubmit = async (values) => {
    storageStore.setSettings(values);
    navigate("/");
  };

  return (
    <S.Wrapper>
      <TopNav>
        <item to="/" icon={lang === 2 ? "right arrow" : "left arrow"} />
      </TopNav>
      <h1>{t("Settings")}</h1>
      <S.Form>
        <Form
          onSubmit={onSubmit}
          render={({ handleSubmit, submitting }) => (
            <form onSubmit={handleSubmit}>
              <div className="input-field">
                <label>{t("Language")}</label>
                <Field
                  name="lang"
                  disabled={submitting}
                  initialValue={settings?.lang || 1}
                >
                  {(props) => {
                    return (
                      <Dropdown
                        search
                        selection
                        options={langOptions}
                        {...props.input}
                        onChange={(e, data) => {
                          props.input.onChange(data.value);
                        }}
                        closeOnChange
                      />
                    );
                  }}
                </Field>
              </div>

              <div className="input-field">
                <label>{t("Currency")}</label>
                <Field
                  name="curr"
                  disabled={submitting}
                  initialValue={settings?.curr || 1}
                >
                  {(props) => (
                    <Dropdown
                      search
                      selection
                      options={currOptions}
                      {...props.input}
                      onChange={(e, data) => {
                        props.input.onChange(data.value);
                      }}
                      closeOnChange
                    />
                  )}
                </Field>
              </div>

              <div className="submit-button">
                <button type="submit" disabled={submitting}>
                  {submitting ? (
                    <Loader active inverted inline size="small" />
                  ) : (
                    <span>{t("Save settings")}</span>
                  )}
                </button>
              </div>
              <div className="exit-button">
                <button
                  type="button"
                  disabled={accountStore.isLoading}
                  onClick={() => accountStore.logout()}
                >
                  {accountStore.isLoading ? (
                    <Loader active inverted inline size="small" />
                  ) : (
                    <span>{t("Sign out")}</span>
                  )}
                </button>
              </div>
            </form>
          )}
        />
      </S.Form>
    </S.Wrapper>
  );
});
